import React from 'react';
import { useFormState } from 'react-final-form';
import { TextInput, SelectInput } from 'react-admin';

const Frequency = (props) => {
  const { values: formvalues } = useFormState();

  if (formvalues === undefined) {
    return null;
  }

  if (
    formvalues.superPost === undefined ||
    formvalues.superPost === false ||
    formvalues.superPostLiveAt === undefined ||
    formvalues.superPostLiveAt === null ||
    formvalues.superPostEndAt === undefined ||
    formvalues.superPostEndAt === null
  ) {
    return <TextInput source="superPostFrequency" disabled />;
  }

  return (
    <SelectInput
      label="Frequency"
      source="superPostFrequency"
      defaultValue="15-min"
      choices={[
        { id: '15-min', name: 'Every 15 minutes' },
        { id: '30-min', name: 'Every 30 minutes' },
        { id: '1-hr', name: 'Every 1 hour' },
        { id: '2-hr', name: 'Every 2 hours' },
        { id: '4-hr', name: 'Every 4 hours' },
        { id: '8-hr', name: 'Every 8 hours' },
      ]}
    />
  );
};

Frequency.propTypes = {};

export default Frequency;
