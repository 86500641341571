import * as React from 'react';
import { RouteWithoutLayout } from 'react-admin';
import { LoginCallback, LoginInit } from './security';

const customRoutes = [
  <RouteWithoutLayout exact path="/login/init" component={LoginInit} />,
  <RouteWithoutLayout exact path="/login/callback" component={LoginCallback} />,
];

export default customRoutes;
