import React from 'react';
import { Admin, Resource } from 'react-admin';
import { createTheme } from '@material-ui/core/styles';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from 'security';
import { createBrowserHistory as createHistory } from 'history';

import CustomLayout from './layout/Layout';
import Login from './layout/Login';

import users from './users';
import permissions from './permissions';
import notifications from './notifications';
import posts from './posts';
import archives from './archives';
import queues from './queues';
import locations from './locations';
import settings from './settings';

import createAuthProvider from './services/authProvider';
import createDataProvider from './services/dataProvider';

import customRoutes from './customRoutes';

const theme = createTheme({
  palette: {
    primary: {
      main: '#00AED9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00619E',
      contrastText: '#fff',
    },
  },
});

// History
const history = createHistory();

// Auth  Provider
const BASENAME = process.env.PUBLIC_URL || '';
const oktaAuth = new OktaAuth({
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}${BASENAME}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
  disableHttpsCheck: true,
});

const authProvider = createAuthProvider(oktaAuth);

// We have to use use a full page refresh so that permissions are correctly pulled
// due to how the code flows in React Admin's Auth provider.
const restoreOriginalUri = async (oktaAuth, originalUri) => {
  window.location.href = originalUri || '/';
};

// Data  Provider
const apiUrl = `${process.env.REACT_APP_API_URL}/v1`;
const dataProvider = createDataProvider(apiUrl, { oktaAuth });

const App = (props) => {
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Admin
        layout={CustomLayout}
        theme={theme}
        history={history}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={Login}
      >
        {(claims) => {
          return [
            <Resource name="queues" {...queues} />,
            <Resource name="posts" {...posts} />,
            <Resource name="archives" {...archives} />,
            <Resource name="locations" {...locations} />,
            claims.role === 'admin' ? <Resource name="users" {...users} /> : null,
            // Permissions are currently unused because groups claim is unavailable and thus we
            // do not infer user's role from their groups.
            // claims.role === 'admin' ? <Resource name="permissions" {...permissions} /> : null,
            claims.role === 'admin' ? <Resource name="notifications" {...notifications} /> : null,
            <Resource name="settings" {...settings} />,
          ];
        }}
      </Admin>
    </Security>
  );
};

export default App;
