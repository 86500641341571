import React from 'react';
import { Edit, SaveButton, SimpleForm, NumberInput, TextInput, Toolbar } from 'react-admin';
import { RoleInput } from '../shared/RoleInput';

const PermissionUpdateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const PermissionEdit = (props) => {
  return (
    <Edit {...props} title="Edit Permission">
      <SimpleForm toolbar={<PermissionUpdateToolbar />}>
        <TextInput source="id" disabled />
        <NumberInput
          source="rank"
          helperText="Users are assigned the highest ranking role when they belong to multiple groups. Lower number means higher rank."
        />
        <TextInput
          label="Group"
          source="group"
          helperText="Active Directory Group that maps to the role selected below"
        />
        <RoleInput
          source="role"
          helperText="User role is used as a fallback so no explicit rule is required"
        />
      </SimpleForm>
    </Edit>
  );
};

export default PermissionEdit;
