import React from 'react';
import { EditButton, useNotify } from 'react-admin';
import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

import DownloadIcon from '@material-ui/icons/CloudDownload';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ImageListItem from '@material-ui/core/ImageListItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import getOptimizedUrl from 'utils/getOptimizedUrl';
import ApproveButton from './ApproveButton';
import PublishButton from './PublishButton';
import RejectButton from './RejectButton';
import SoftRejectButton from './SoftRejectButton';
import UnpublishButton from './UnpublishButton';
import ArchiveButton from './ArchiveButton';

const cardStyle = {
  // width: '100%',
  margin: '0.5em',
  // display: 'inline-block',
  // verticalAlign: 'top',
  height: '100%',
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginLeft: '-20px',
    padding: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  missingImage: {
    color: 'red',
  },
  liveAtDiv: {
    ...theme.typography.button,
    backgroundColor: '#ddd',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  editButton: {
    zIndex: 3000,
  },
  assetContainer: {
    maxHeight: 250,
  },
  image: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  video: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  gridList: {
    listStyle: 'none',
  },
  infoSection: {
    padding: theme.spacing(1),
  },
  downloadIcon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

const PreviewCard = (props) => {
  const { ids, data, basePath } = props;
  const notify = useNotify();
  const classes = useStyles();

  const handleDownload = (asset) => {
    const fileName = `${asset.public_id}.${asset.format}`;
    notify('Retrieving asset to download, please be patient', { type: 'success' });
    saveAs(asset.secure_url, fileName);
  };

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      {ids.map((id) => {
        const record = data[id];
        const asset =
          record !== undefined && record.asset !== undefined && record.asset !== null
            ? record.asset
            : null;
        const { assetUrl } = record;
        const assetType = asset !== null ? asset.resource_type : null;
        const assetFormat = asset !== null ? asset.format : null;
        const creator =
          record.creator !== null
            ? `${record.creator.firstName} ${record.creator.lastName}`
            : 'Email Service';

        return (
          <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
            <Card style={cardStyle}>
              <ImageListItem className={classes.gridList}>
                {asset !== null && assetType === 'image' && assetFormat !== 'gif' && (
                  <div className={classes.assetContainer}>
                    <img
                      src={getOptimizedUrl({
                        publicId: asset.public_id,
                        cloudName: process.env.REACT_APP_CLOUDINARY_API_KEY_CLOUD_NAME,
                        resourceType: assetType,
                        width: 347,
                        height: 195,
                      })}
                      className={classes.image}
                      alt="Post Asset"
                    />
                  </div>
                )}

                {asset !== null && assetType === 'image' && assetFormat === 'gif' && (
                  <div className={classes.assetContainer}>
                    <img
                      src={getOptimizedUrl({
                        publicId: asset.public_id,
                        cloudName: process.env.REACT_APP_CLOUDINARY_API_KEY_CLOUD_NAME,
                        resourceType: assetType,
                        width: 347,
                        height: 195,
                        crop: 'fill',
                      })}
                      className={classes.image}
                      alt="Post Gif Asset"
                    />
                  </div>
                )}

                {assetUrl !== null && assetType === 'video' && (
                  <div className={classes.assetContainer}>
                    <video controls className={classes.video}>
                      <source type="video/mp4" src={assetUrl} />
                    </video>
                  </div>
                )}

                <div className={classes.infoSection}>
                  {assetType === null && record.mediaTransferring === true && (
                    <div>
                      <Typography variant="subtitle1" className={classes.missingImage}>
                        Media Transferring. Refresh the page in a few seconds.
                      </Typography>
                    </div>
                  )}
                  {assetType === null && record.mediaTransferring !== true && (
                    <div>
                      <Typography variant="h5" className={classes.missingImage}>
                        No asset added
                      </Typography>
                    </div>
                  )}
                  <Typography variant="subtitle1">
                    By: {record.firstName} {record.lastName}
                  </Typography>
                  <Typography variant="body1">Creator: {creator}</Typography>
                  <Typography variant="body1">Email: {record.email}</Typography>
                  <Typography variant="body1">Subject: {record.subject}</Typography>
                  <Typography variant="body1">
                    Published: {record.published ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body1">
                    Created At: {moment(record.createdAt).format('MM/DD/YYYY, h:mm A')}
                  </Typography>
                  <Typography variant="body1">Likes: {record.likeCount}</Typography>
                  {record.publishedAt !== null && (
                    <Typography variant="body1">
                      Published At: {moment(record.publishedAt).format('MM/DD/YYYY, h:mm A')}
                    </Typography>
                  )}
                  <div className={classes.actions}>
                    <EditButton
                      resource="posts"
                      basePath={basePath}
                      record={data[id]}
                      className={classes.editButton}
                    />

                    {assetType !== null &&
                      record.liveAt === null &&
                      record.published === false &&
                      record.superPost === false && (
                        <PublishButton record={record} className={classes.editButton} />
                      )}

                    {assetType !== null &&
                      record.liveAt === null &&
                      record.published === false &&
                      record.superPost === true && (
                        <ApproveButton record={record} className={classes.editButton} />
                      )}

                    {record.published === false && (
                      <RejectButton record={record} className={classes.editButton} />
                    )}

                    {record.published === false && (
                      <SoftRejectButton record={record} className={classes.editButton} />
                    )}

                    {record.published === true && (
                      <UnpublishButton record={record} className={classes.editButton} />
                    )}

                    {record.published === true && (
                      <ArchiveButton record={record} className={classes.editButton} />
                    )}

                    {asset !== null && asset.resource_type === 'image' && (
                      <Button size="small" color="primary" onClick={() => handleDownload(asset)}>
                        <DownloadIcon className={classes.downloadIcon} />
                        Download Image
                      </Button>
                    )}

                    {asset !== null && asset.resource_type === 'video' && (
                      <Button size="small" color="primary" onClick={() => handleDownload(asset)}>
                        <DownloadIcon className={classes.downloadIcon} />
                        Download Video
                      </Button>
                    )}

                    {record.liveAt !== null && (
                      <div className={classes.liveAtDiv}>
                        Scheduled at:{' '}
                        {moment
                          .tz(record.liveAt, record.liveAtTimeZone)
                          .format('MM/DD/YYYY, h:mm A')}{' '}
                        ({record.liveAtTimeZone})
                      </div>
                    )}

                    {record.superPost && (
                      <div className={classes.liveAtDiv}>
                        Super post scheduled:
                        <br />
                        {moment
                          .tz(record.superPostLiveAt, record.superPostTimeZone)
                          .format('MM/DD/YYYY, h:mm A')}{' '}
                        {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                        <br />
                        thru
                        <br />
                        {moment
                          .tz(record.superPostEndAt, record.superPostTimeZone)
                          .format('MM/DD/YYYY, h:mm A')}{' '}
                        {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                      </div>
                    )}
                  </div>
                  <Typography variant="caption">
                    <strong>Location:</strong> {record.locationTitle}
                  </Typography>
                  &nbsp;
                  <Typography variant="caption">
                    <strong>Location Code:</strong> {record.locationCode}
                  </Typography>
                </div>
              </ImageListItem>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PreviewCard;
