import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../DateTime';

const useStyles = makeStyles((theme) => ({
  span: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LiveAt = (props) => {
  const classes = useStyles();
  const form = useForm();
  const { values: formvalues } = useFormState();

  const handleDateChange = (date) => {
    if (date === '') {
      form.change('superPostLiveAt', null);
      return;
    }

    form.change('superPostLiveAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const superPostLiveAt =
    formvalues !== undefined &&
    formvalues.superPostLiveAt !== undefined &&
    formvalues.superPostLiveAt !== null
      ? moment(formvalues.superPostLiveAt)
      : null;
  const superPostTimeZone =
    formvalues !== undefined && formvalues.superPostTimeZone !== null
      ? formvalues.superPostTimeZone
      : 'America/Los_Angeles';

  if (formvalues.superPost === undefined || formvalues.superPost === false) {
    return <TextInput source="superPostLiveAtDate" label="Super post live date" disabled />;
  }

  const valid = (current) => {
    if (!formvalues.superPostEndAt) {
      return true;
    }

    const end = moment(formvalues.superPostEndAt);
    return current.isSameOrBefore(end, 'day');
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={classes.span}>Super post live date</span>
        <DateTime
          dateFormat="MM/DD/YYYY"
          timeFormat="h:mm a"
          displayTimeZone={superPostTimeZone}
          onChange={handleDateChange}
          isValidDate={valid}
          value={superPostLiveAt}
        />
      </div>
    </Fragment>
  );
};

LiveAt.propTypes = {};

export default LiveAt;
