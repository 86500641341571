import React from 'react';
import { useNotify, useRefresh } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import UnpublishIcon from '@material-ui/icons/Block';
import { request } from '../../services/request';
import { useOktaAuth } from '../../security/OktaContext';

const apiUrl = `${process.env.REACT_APP_API_URL}/v1`;

const useStyles = makeStyles((theme) => ({
  approveButton: {
    color: '#f44336',
    zIndex: 3000,
    marginLeft: theme.spacing(1),
  },
  approveIcon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
  downloadIcon: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

const UnpublishButton = (props) => {
  const { oktaAuth } = useOktaAuth();
  const refresh = useRefresh();
  const notify = useNotify();
  const classes = useStyles();

  const handleClick = async () => {
    const { record } = props;

    const updatedRecord = {
      ...record,
      rejected: false,
      published: false,
      publishedAt: null,
      superPost: false,
      superPostTimeZone: '',
      superPostLiveAt: null,
      superPostEndAt: null,
      superPostFrequency: '',
    };

    const requestUrl = `${apiUrl}/queues/${record.id}`; // eslint-disable-line
    const AuthorizationString = `Bearer ${oktaAuth.getAccessToken()}`;

    const requestOptions = {
      url: requestUrl,
      method: 'put',
      headers: {
        Accept: 'application/json',
        Authorization: AuthorizationString,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(updatedRecord),
    };

    try {
      await request(requestOptions);
      refresh();
      notify('Post un-published', { type: 'success' });
    } catch (err) {
      notify(err.errorData.message, { type: 'warning' });
    }
  };

  return (
    <Button size="small" onClick={handleClick} className={classes.approveButton}>
      <UnpublishIcon className={classes.downloadIcon} />
      UN-PUBLISH
    </Button>
  );
};

export default UnpublishButton;
