import React, { Fragment, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  Edit,
  BooleanInput,
  DateField,
  DateTimeInput,
  email,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
} from 'react-admin';
import DropzoneUploader from '../shared/DropzoneUploader';
import RemoveLocation from '../shared/RemoveLocation';
import SuperPost from '../shared/SuperPost';

const validateEmail = email();

const validateQueueFields = (values, props) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.subject) {
    errors.subject = 'Subject is required';
  }
  if (values.subject && values.subject.length > 128) {
    errors.subject = 'Subject is to long, max 128 characters';
  }
  if (values.superPost === true) {
    if (
      values.superPostFrequency === undefined ||
      values.superPostFrequency === null ||
      values.superPostFrequency === ''
    ) {
      errors.superPost = 'Super Post frequency is required';
    }
    if (values.superPostEndAt === undefined || values.superPostEndAt === null) {
      errors.superPost = 'Super Post end at is required';
    }

    if (values.superPostLiveAt === undefined || values.superPostLiveAt === null) {
      errors.superPost = 'Super Post live at is required';
    }
  }

  return errors;
};

const PostToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} redirect={false} />
    </Toolbar>
  );
};

const PublishContent = (props) => {
  const { record } = props;

  // Anyone can unpublish
  if (record.pushished === true) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  // Only moderators can publish
  if (!record.pushlished) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  return (
    <Fragment>
      <TextInput source="published" helperText="Only moderators can publish content." disabled />
    </Fragment>
  );
};

const FieldController = (props) => {
  const form = useForm();
  const { values } = useFormState();

  useEffect(() => {
    if (
      values.asset === null ||
      (values.asset !== null && values.asset.resource_type === 'image')
    ) {
      form.change('playAudio', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.asset]);

  useEffect(() => {
    if (values.superPost === false) {
      form.change('superPostLiveAt', null);
      form.change('superPostEndAt', null);
      form.change('superPostFrequency', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.superPost]);

  return null;
};

const PostEdit = (props) => {
  return (
    <Edit {...props} title="Edit Post">
      <TabbedForm toolbar={<PostToolbar />} validate={validateQueueFields}>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <PublishContent />
          <TextInput source="subject" />
          <TextInput label="First Name" source="firstName" />
          <TextInput label="Last Name" source="lastName" />
          <TextInput label="Email Address" source="email" type="email" validate={validateEmail} />
          <DateTimeInput
            label="Published At"
            source="publishedAt"
            helperText="Update the value changes where it is listed regarding all other posts"
          />
          <DateField source="createdAt" showTime />
          <DateField source="updatedAt" showTime />

          <FieldController {...props} />
        </FormTab>

        <FormTab label="attachment">
          <DropzoneUploader />
        </FormTab>

        <FormTab label="super post">
          <SuperPost />
        </FormTab>

        <FormTab label="location">
          <ReferenceInput
            source="location"
            reference="locations"
            perPage={1000}
            sort={{ field: 'location', order: 'ASC' }}
          >
            <AutocompleteInput optionText="location" />
          </ReferenceInput>
          <RemoveLocation />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default PostEdit;
