import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../DateTime';

const useStyles = makeStyles((theme) => ({
  span: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EndAt = (props) => {
  const classes = useStyles();
  const form = useForm();
  const { values: formvalues } = useFormState();

  const handleDateChange = (date) => {
    if (date === '') {
      form.change('superPostEndAt', null);
      return;
    }

    const begin = moment(formvalues.superPostLiveAt);
    const end = moment(date);

    if (begin.isAfter(end)) {
      form.change('superPostEndAt', null);
      return;
    }

    form.change('superPostEndAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const superPostEndAt =
    formvalues !== undefined &&
    formvalues.superPostEndAt !== undefined &&
    formvalues.superPostEndAt !== null
      ? moment(formvalues.superPostEndAt)
      : '';
  const superPostTimeZone =
    formvalues !== undefined && formvalues.superPostTimeZone !== null
      ? formvalues.superPostTimeZone
      : 'America/Los_Angeles';

  if (
    formvalues.superPost === undefined ||
    formvalues.superPost === false ||
    formvalues.superPostLiveAt === undefined ||
    formvalues.superPostLiveAt === null
  ) {
    return <TextInput source="superPostEndAtDate" label="Super post end date" disabled />;
  }

  const valid = (current) => {
    const begin = moment(formvalues.superPostLiveAt);
    return current.isSameOrAfter(begin, 'day');
  };

  return (
    <Fragment>
      <div className={classes.container}>
        <span className={classes.span}>Super post end date</span>
        <DateTime
          dateFormat="MM/DD/YYYY"
          timeFormat="h:mm a"
          displayTimeZone={superPostTimeZone}
          onChange={handleDateChange}
          isValidDate={valid}
          value={superPostEndAt}
        />
      </div>
    </Fragment>
  );
};

EndAt.propTypes = {};

export default EndAt;
