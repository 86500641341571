import LocationIcon from '@material-ui/icons/LocationCity';
import LocationList from './LocationList';
import LocationEdit from './LocationEdit';
import LocationCreate from './LocationCreate';

const locations = {
  create: LocationCreate,
  list: LocationList,
  edit: LocationEdit,
  icon: LocationIcon,
};

export default locations;
