import React, { Fragment, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import classNames from 'classnames';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  assetActions: {
    flexDirection: 'column',
    display: 'flex',
    width: '125px',
  },
  assetButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    paddingLeft: 0,
    paddingBottom: '0 !important',
  },
  removeButton: {
    marginLeft: theme.spacing(1),
  },
  dropzoneContainer: {
    marginTop: theme.spacing(2),
  },
  dropzone: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#999',
  },
  card: {
    maxWidth: 545,
  },
  media: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  video: {
    maxWidth: '350px',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  mediaContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const { REACT_APP_CLOUDINARY_API_UPLOAD_PRESET, REACT_APP_CLOUDINARY_API_KEY } = process.env;

const DropzoneUploader = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const form = useForm();
  const { values: formvalues } = useFormState();
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onFileDrop = async (files) => {
    try {
      toggleUpload();

      const formData = new FormData();
      const file = files[0];
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      let isValid = false;

      if (typeof ext === 'string') {
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'webp':
          case 'gif':
          case 'mov':
          case 'mp4':
          case 'm4v':
          case 'wmv':
          case '3gp':
          case 'mkv':
          case 'webm':
            isValid = true;
            break;
          default:
            isValid = false;
        }
      }

      if (!isValid) {
        notify('Invalid attachment provided', { type: 'warning' });
        setUploadProgress(0);
        setUploading(false);

        return;
      }

      const cloudName = process.env.REACT_APP_CLOUDINARY_API_KEY_CLOUD_NAME;

      let url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;

      formData.append('file', file);
      formData.append('upload_preset', REACT_APP_CLOUDINARY_API_UPLOAD_PRESET); // Replace the preset name with your own
      // TODO: this requires signed upload which requires refactoring of the upload flow
      // formData.append('transformation', 'fl_force_strip');
      formData.append('api_key', REACT_APP_CLOUDINARY_API_KEY); // Replace API key with your own Cloudinary API key
      formData.append('timestamp', (Date.now() / 1000) | 0);

      const response = await axios.post(url, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        onUploadProgress: (progressEvent) => {
          const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(uploadProgress);
        },
      });

      form.change('asset', response.data);
    } catch (err) {
      console.error(err);
      notify('Invalid attachment provided', { type: 'warning' });
      setUploadProgress(0);
      setUploading(false);
      return;
    }

    toggleUpload();
    setUploadProgress(0);
  };

  const toggleUpload = () => {
    setUploading(!uploading);
  };

  const handleRemoveAsset = () => {
    form.change('assetUrl', null);
    form.change('asset', null);
  };

  let thumbnail;
  let videoUrl;

  if (formvalues !== undefined && formvalues.asset !== null && formvalues.assetUrl !== null) {
    if (formvalues.asset.resource_type === 'image') {
      thumbnail = formvalues.assetUrl;
    }

    if (formvalues.asset.resource_type === 'video') {
      videoUrl = formvalues.assetUrl;
    }
  }

  if (
    formvalues !== undefined &&
    formvalues.asset !== null &&
    (formvalues.assetUrl === undefined || formvalues.assetUrl === null)
  ) {
    let secure_url = formvalues.asset.secure_url;

    switch (formvalues.asset.resource_type) {
      case 'image':
        if (formvalues.asset.format === 'gif') {
          thumbnail = secure_url.replace('/image/upload/', '/image/upload/h_300/');
        } else {
          thumbnail = secure_url.replace('/image/upload/', '/image/upload/h_300/');
          thumbnail = thumbnail.substr(0, thumbnail.lastIndexOf('.')) + '.jpg';
        }
        break;
      case 'video':
        videoUrl = secure_url.substr(0, secure_url.lastIndexOf('.')) + '.mp4';
        break;
      default:
        thumbnail = secure_url;
        videoUrl = secure_url;
    }
  }

  return (
    <Fragment>
      {formvalues !== undefined &&
        formvalues.asset !== null &&
        formvalues.asset.resource_type === 'image' && (
          <div className={classes.mediaContainer}>
            <img src={thumbnail} className={classes.media} alt="Post Asset" />

            <CardContent className={classes.buttonContainer}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.assetButtons}
                href={formvalues.asset.secure_url}
                target="_blank"
              >
                Open Url
              </Button>

              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.removeButton}
                onClick={handleRemoveAsset}
              >
                Remove
              </Button>
            </CardContent>
          </div>
        )}

      {formvalues !== undefined &&
        formvalues.asset !== null &&
        formvalues.asset.resource_type === 'video' && (
          <div className={classes.mediaContainer}>
            <video className={classes.video} controls>
              <source type="video/mp4" src={videoUrl} />
            </video>

            <CardContent className={classes.buttonContainer}>
              <Typography variant="body1" gutterBottom>
                Filename: {formvalues.asset.original_filename}
              </Typography>

              <Typography variant="body1" gutterBottom>
                Duration: {formvalues.asset.duration}
              </Typography>

              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.assetButtons}
                href={formvalues.asset.secure_url}
                target="_blank"
              >
                Open Url
              </Button>

              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.removeButton}
                onClick={handleRemoveAsset}
              >
                Remove
              </Button>
            </CardContent>
          </div>
        )}

      {formvalues !== undefined && formvalues.asset === null && (
        <div className={classes.dropzoneContainer}>
          <Dropzone
            onDrop={onFileDrop}
            accept="video/*,image/*"
            multiple={false}
            disabled={uploading}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <Fragment>
                  <div
                    {...getRootProps()}
                    className={classNames(`${classes.dropzone}`, {
                      'dropzone--isActive': isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />
                    {uploading ? <p>Uploading</p> : <p>Drop the image or video here</p>}
                  </div>

                  {uploading && <LinearProgress variant="determinate" value={uploadProgress} />}
                </Fragment>
              );
            }}
          </Dropzone>
        </div>
      )}
    </Fragment>
  );
};

DropzoneUploader.propTypes = {};

export default DropzoneUploader;
