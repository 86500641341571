import React, { Fragment } from 'react';
import { useForm, useFormState } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import { TextInput, SelectInput } from 'react-admin';

const TakeoverDuration = (props) => {
  const form = useForm();
  const { values: formvalues } = useFormState();

  if (formvalues === undefined) return null;

  if (!formvalues.takeoverModeBool) {
    return <TextInput label="Takeover duration" source="takeoverDuration" disabled />;
  }

  if (!formvalues.asset) {
    return (
      <Fragment>
        <TextInput label="Default duration" source="takeoverDuration" disabled />
        <Typography variant="caption">
          <p>Since there is no asset attached yet, default duration will be set:</p>
          <ul>
            <li>10 seconds for images</li>
            <li>Full length for videos</li>
          </ul>
        </Typography>
      </Fragment>
    );
  }

  let durationOptions = [
    { id: 'PT10S', name: '10 seconds' },
    { id: 'PT20S', name: '20 seconds' },
    { id: 'PT30S', name: '30 seconds' },
    { id: 'PT1M', name: '1 minute' },
  ];

  if (formvalues.asset.resource_type === 'video') {
    durationOptions.unshift({ id: 'FULL_LENGTH', name: 'Full Length of Video' });
  }

  let defaultValue = durationOptions[0].id;

  if (!formvalues.takeoverDuration) {
    form.change('takeoverDuration', defaultValue);
  }

  return (
    <SelectInput
      label="Takeover duration"
      source="takeoverDuration"
      initialValue={defaultValue}
      choices={durationOptions}
    />
  );
};

TakeoverDuration.propTypes = {};

export default TakeoverDuration;
