import React from 'react';
import { Create, SimpleForm, SaveButton, NumberInput, TextInput, Toolbar } from 'react-admin';
import { RoleInput } from '../shared/RoleInput';

const PermissionCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Create Permission" redirect="show" submitOnEnter={true} />
    </Toolbar>
  );
};

const PermissionCreate = (props) => {
  return (
    <Create {...props} title="Create Permission">
      <SimpleForm toolbar={<PermissionCreateToolbar />}>
        <NumberInput
          source="rank"
          helperText="Users are assigned the highest ranking role when they belong to multiple groups. Lower number means higher rank."
        />
        <TextInput
          label="Group"
          source="group"
          helperText="Active Directory Group that maps to the role selected below"
        />
        <RoleInput
          source="role"
          helperText="User role is used as a fallback so no explicit rule is required"
        />
      </SimpleForm>
    </Create>
  );
};

export default PermissionCreate;
