import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useFormState, useForm } from 'react-final-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../shared/DateTime';

const useStyles = makeStyles((theme) => ({
  span: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DateInput = (props) => {
  const classes = useStyles();
  const form = useForm();
  const { values: formvalues } = useFormState();

  const handleDateChange = (date) => {
    if (date === '') {
      form.change('published', false);
      form.change('liveAt', null);
      form.change('liveAtTimeZone', 'America/Los_Angeles');
      return;
    }

    form.change('published', false);
    form.change('liveAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const liveAt =
    formvalues !== undefined && formvalues.liveAt !== null ? moment(formvalues.liveAt) : '';
  const liveAtTimeZone =
    formvalues !== undefined && formvalues.liveAtTimeZone !== null
      ? formvalues.liveAtTimeZone
      : 'America/Los_Angeles';

  return (
    <Fragment>
      {formvalues.asset === null && <TextInput source="liveAt" disabled />}

      {formvalues.asset !== null && (
        <div className={classes.container}>
          <span className={classes.span}>Live at date</span>
          <DateTime
            dateFormat="MM/DD/YYYY"
            timeFormat="h:mm a"
            displayTimeZone={liveAtTimeZone}
            onChange={handleDateChange}
            value={liveAt}
          />
        </div>
      )}
    </Fragment>
  );
};

DateInput.propTypes = {};

export default DateInput;
