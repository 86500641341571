import React from 'react';
import { Edit, DateField, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

const validateQueueFields = (values, props) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Location title is required';
  }

  return errors;
};

const LocationToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const LocationEdit = (props) => {
  return (
    <Edit {...props} title="Edit Location">
      <SimpleForm toolbar={<LocationToolbar />} validate={validateQueueFields}>
        <TextInput source="id" disabled />
        <TextInput label="Location Title" source="location" />
        <TextInput source="slug" disabled />
        <TextInput label="Location Code" source="code" />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </SimpleForm>
    </Edit>
  );
};

export default LocationEdit;
