import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import {
  BooleanInput,
  DateField,
  Edit,
  FormDataConsumer,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import DropzoneUploader from '../shared/DropzoneUploader';

const validateQueueFields = (values, props) => {
  const errors = {};
  if (!values.queueLimit) {
    errors.queueLimit = 'Queue limit is required';
  }
  if (values.queueLimit && !Number.isInteger(values.queueLimit)) {
    errors.queueLimit = 'Queue limit must be a whole number';
  }
  if (values.queueLimit && values.queueLimit < 50) {
    errors.queueLimit = 'Queue limit must be a atleast 50';
  }
  return errors;
};

const SettingsToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const FieldController = (props) => {
  const form = useForm();
  const { values } = useFormState();

  useEffect(() => {
    if (!values.asset) {
      form.change('published', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.asset]);

  return null;
};

const SettingEdit = (props) => {
  return (
    <Edit {...props} title="Edit Queue">
      <SimpleForm toolbar={<SettingsToolbar />} validate={validateQueueFields}>
        <TextInput source="id" disabled />
        <BooleanInput source="active" />

        <NumberInput
          source="queueLimit"
          step={1}
          defaultValue={50}
          helperText="The number of posts in rotation on the screen. Default is 50."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.imageUrl === undefined || formData.imageUrl === '') && <DropzoneUploader />
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.asset === undefined || formData.asset === null) && (
              <TextInput label="Image URL" source="imageUrl" fullWidth />
            )
          }
        </FormDataConsumer>

        <DateField source="updatedAt" showTime />

        <FieldController {...props} />
      </SimpleForm>
    </Edit>
  );
};

export default SettingEdit;
