import React, { Fragment } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  removeLocation: {
    marginTop: theme.spacing(1),
  },
}));

const LocationInput = (props) => {
  const classes = useStyles();
  const form = useForm();
  const { values: formvalues } = useFormState();

  const handleRemoveLocation = () => {
    form.change('location', null);
  };

  const location = formvalues !== undefined && formvalues.location ? formvalues.location : null;

  return (
    <Fragment>
      {location !== null && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleRemoveLocation}
          className={classes.removeLocation}
        >
          Remove Location
        </Button>
      )}
    </Fragment>
  );
};

LocationInput.propTypes = {};

export default LocationInput;
