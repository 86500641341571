import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useFormState } from 'react-final-form';
import { BooleanInput } from 'react-admin';
import Timezone from './Timezone';
import LiveAt from './LiveAt';
import EndAt from './EndAt';
import TakeoverDuration from './TakeoverDuration';
import Frequency from './Frequency';

const useStyles = makeStyles((theme) => ({
  span: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SuperPost = (props) => {
  const { values: formvalues } = useFormState();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BooleanInput source="superPost" label="Enable a Super Post" />
      <Timezone />
      <LiveAt />
      <EndAt />
      <Frequency />
      {formvalues.superPost && (
        <Fragment>
          <BooleanInput source="takeoverModeBool" label="Perform Full Screen Takeover" />
          <Typography variant="caption">
            <p>
              The post will be shown in full screen for a duration of your choosing at the selected
              frequency.
            </p>
          </Typography>
          <Typography variant="caption">
            For good display resolution at full screen, ensure images and videos are at least
            1920x1080px and no larger than 250MB.
          </Typography>
          <TakeoverDuration />
        </Fragment>
      )}
    </div>
  );
};

SuperPost.propTypes = {};

export default SuperPost;
