import QueueIcon from '@material-ui/icons/CompareArrows';
import QueueCreate from './QueueCreate';
import QueueEdit from './QueueEdit';
import QueueList from './QueueList';

const queues = {
  create: QueueCreate,
  list: QueueList,
  edit: QueueEdit,
  icon: QueueIcon,
};

export default queues;
