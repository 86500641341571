import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// import * as serviceWorker from './serviceWorker';

const app = (
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const target = document.querySelector('#root');

// Good old approach
ReactDOM.render(app, target);

// Concurrent mode (not fully stable yet)
// ReactDOM.createRoot(target).render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
