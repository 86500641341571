import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Create, email, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

const validateEmail = email();

const validateQueueFields = (values, props) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (values.firstName && values.firstName.length > 128) {
    errors.firstName = 'First name is to long, max 128 characters';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (values.lastName && values.lastName.length > 128) {
    errors.lastName = 'Last name is to long, max 128 characters';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.subject) {
    errors.subject = 'Subject is required';
  }
  if (values.subject && values.subject.length > 128) {
    errors.subject = 'Subject is to long, max 128 characters';
  }

  return errors;
};

const QueueToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const FieldController = (props) => {
  const form = useForm();
  const { values } = useFormState();

  useEffect(() => {
    if (!values.asset) {
      form.change('published', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.asset]);

  return null;
};

const QueueCreate = (props) => {
  return (
    <Create {...props} title="Create Post">
      <SimpleForm toolbar={<QueueToolbar />} validate={validateQueueFields}>
        <TextInput source="firstName" label="First Name" />
        <TextInput source="lastName" label="Last Name" />
        <TextInput source="email" label="Email Address" type="email" validate={validateEmail} />
        <TextInput source="subject" />
        <FieldController {...props} />
      </SimpleForm>
    </Create>
  );
};

export default QueueCreate;
