import axios from 'axios';

// eslint-disable-next-line import/no-anonymous-default-export
const createAuthProvider = (oktaClient) => ({
  // authentication
  login: async () => {
    oktaClient.signInWithRedirect({ originalUri: '/' });
    return new Promise(() => {});
  },

  checkError: async (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      oktaClient.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });

      return new Promise(() => {});
    }
    return Promise.resolve();
  },

  checkAuth: async () => {
    if (oktaClient.isAuthenticated) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },

  logout: async () => {
    if (oktaClient.isAuthenticated) {
      oktaClient.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }

    return new Promise(() => {});
  },

  getIdentity: async () => {
    const user = await oktaClient.getUser();
    return {
      email: user.email,
      username: user.preferred_username,
      timezone: user.zoneinfo,
      locale: user.locale,
      givenName: user.given_name,
      familyName: user.family_name,
      fullName: user.name,
    };
  },

  // authorization
  getPermissions: async () => {
    // Public routes
    if (
      window.location.pathname === '/login/init' ||
      window.location.pathname === '/login/callback'
    ) {
      return {
        role: 'user',
      };
    }

    const accessToken = oktaClient.getAccessToken();
    if (accessToken) {
      try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/v1`;
        const response = await axios({ url: `${apiUrl}/users/me` });
        const user = response.data;

        return {
          role: user.role,
        };
      } catch (err) {
        console.error(err);
      }
    }

    return Promise.reject();
  },
});

export default createAuthProvider;
