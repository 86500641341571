import React from 'react';
import { useLocation } from 'react-router-dom';
import { useLogin, Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import OktaError from 'security/OktaError';

const useStyles = makeStyles((theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#00AED9',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Login = (props) => {
  const location = useLocation();
  const { theme } = props;
  const login = useLogin();
  const classes = useStyles();
  const locationState = location.state || {};
  const loginError = locationState.error;

  return (
    <MuiThemeProvider theme={theme}>
      <main className={classes.main}>
        <Paper className={classes.paper} elevation={2}>
          <Avatar className={classes.avatar}>
            <LockIcon />
          </Avatar>

          <Typography variant="h5">Carvana Collective</Typography>

          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={() => login({ provider: 'okta' })}
          >
            Sign in with Okta
          </Button>

          {loginError && <OktaError error={loginError} />}

          <Notification />
        </Paper>
      </main>
    </MuiThemeProvider>
  );
};

export default Login;
