import ArchiveIcon from '@material-ui/icons/Archive';
import ArchiveList from './ArchiveList';
import ArchiveEdit from './ArchiveEdit';

const archives = {
  list: ArchiveList,
  edit: ArchiveEdit,
  icon: ArchiveIcon,
};

export default archives;
