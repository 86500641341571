import React from 'react';
import {
  TopToolbar,
  Filter,
  List,
  Pagination,
  RefreshButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import ArchiveCard from '../shared/ArchiveCard';

const Actions = (props) => {
  return (
    <TopToolbar>
      <RefreshButton />
    </TopToolbar>
  );
};

const PostPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />

      <ReferenceInput
        label="Locations"
        source="location"
        reference="locations"
        perPage={1000}
        sort={{ field: 'location', order: 'ASC' }}
        alwaysOn
        allowEmpty={false}
      >
        <AutocompleteInput optionText="location" resettable />
      </ReferenceInput>

      <SelectInput
        label="Post Type"
        source="type"
        alwaysOn
        allowEmpty={false}
        choices={[
          { id: 'all', name: 'All' },
          { id: 'post', name: 'Post' },
          { id: 'superpost', name: 'Superpost' },
        ]}
      />
    </Filter>
  );
};

const ArchiveList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      perPage={10}
      pagination={<PostPagination />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      title="Carvana Collective - Posts"
      actions={<Actions />}
      filters={<PostFilter />}
    >
      <ArchiveCard permissions={permissions} />
    </List>
  );
};

export default ArchiveList;
