import React from 'react';
import {
  TopToolbar,
  CreateButton,
  Filter,
  List,
  Pagination,
  RefreshButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
} from 'react-admin';
import PreviewCard from '../shared/PreviewCard';

const Actions = ({ basePath }) => {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <RefreshButton />
    </TopToolbar>
  );
};

const PostPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />

      <ReferenceInput
        label="Locations"
        source="location"
        reference="locations"
        perPage={1000}
        sort={{ field: 'location', order: 'ASC' }}
        alwaysOn
        allowEmpty={false}
      >
        <AutocompleteInput optionText="location" resettable />
      </ReferenceInput>

      <SelectInput
        label="Post Type"
        source="type"
        alwaysOn
        allowEmpty={false}
        choices={[
          { id: 'all', name: 'All' },
          { id: 'post', name: 'Post' },
          { id: 'superpost', name: 'Superpost' },
        ]}
      />
    </Filter>
  );
};

const QueueList = ({ permissions, ...props }) => {
  return (
    <List
      {...props}
      perPage={10}
      pagination={<PostPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title="Carvana Collective - Moderation Queue"
      actions={<Actions />}
      filters={<PostFilter />}
    >
      <PreviewCard permissions={permissions} />
    </List>
  );
};

export default QueueList;
