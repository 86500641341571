import React from 'react';
import {
  Edit,
  DateField,
  email,
  ReferenceInput,
  SaveButton,
  AutocompleteInput,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { RoleInput } from '../shared/RoleInput';

const validateEmail = email();

const validateUserCreation = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  }
  if (!values.location) {
    errors.location = 'Location is required';
  }
  if (!values.status) {
    errors.status = 'Status is required';
  }
  if (!values.role) {
    errors.role = 'Role is required';
  }

  return errors;
};

const UserToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const UserEdit = (props) => {
  return (
    <Edit {...props} title="Edit User">
      <SimpleForm toolbar={<UserToolbar />} validate={validateUserCreation}>
        <TextInput source="id" disabled />
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <TextInput label="Email Address" source="email" type="email" validate={validateEmail} />
        <ReferenceInput
          source="location"
          reference="locations"
          perPage={1000}
          sort={{ field: 'location', order: 'ASC' }}
        >
          <AutocompleteInput optionText="location" />
        </ReferenceInput>
        <RoleInput
          source="role"
          helperText="User role is used as a fallback so no explicit rule is required"
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
