import UserIcon from '@material-ui/icons/Group';
import UserList from './UserList';
import UserEdit from './UserEdit';

const users = {
  list: UserList,
  edit: UserEdit,
  icon: UserIcon,
};

export default users;
