import PostIcon from '@material-ui/icons/Tv';
import PostEdit from './PostEdit';
import PostList from './PostList';

const posts = {
  edit: PostEdit,
  list: PostList,
  icon: PostIcon,
};

export default posts;
