import React from 'react';
import { Create, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

const validateQueueFields = (values, props) => {
  const errors = {};
  if (!values.location) {
    errors.location = 'Location is required';
  }

  if (!values.code) {
    errors.code = 'Location code is required';
  }

  return errors;
};

const LocationToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" submitOnEnter={true} />
    </Toolbar>
  );
};

const LocationCreate = (props) => {
  return (
    <Create {...props} title="Create Location">
      <SimpleForm toolbar={<LocationToolbar />} validate={validateQueueFields}>
        <TextInput label="Location" source="location" />
        <TextInput label="Location Code" source="code" />
      </SimpleForm>
    </Create>
  );
};

export default LocationCreate;
