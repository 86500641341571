import PermissionIcon from '@material-ui/icons/Security';
import PermissionList from './PermissionList';
import PermissionCreate from './PermissionCreate';
import PermissionEdit from './PermissionEdit';

const permissions = {
  create: PermissionCreate,
  list: PermissionList,
  edit: PermissionEdit,
  icon: PermissionIcon,
};

export default permissions;
