import React from 'react';
import {
  Filter,
  TextInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TopToolbar,
  Datagrid,
  DateField,
  TextField,
  EmailField,
  List,
  Pagination,
  RefreshButton,
  EditButton,
} from 'react-admin';
import { RoleInput } from '../shared/RoleInput';
import { RoleField } from '../shared/RoleField';

const Actions = ({ basePath }) => {
  return (
    <TopToolbar>
      <RefreshButton />
    </TopToolbar>
  );
};

const UserPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const UserFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
      <ReferenceInput
        label="Locations"
        source="location"
        reference="locations"
        perPage={1000}
        sort={{ field: 'location', order: 'ASC' }}
        alwaysOn
        allowEmpty={false}
      >
        <AutocompleteInput optionText="location" resettable />
      </ReferenceInput>
      <RoleInput />
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        allowEmpty={false}
        choices={[
          { id: 'Active', name: 'Active' },
          { id: 'On Leave', name: 'On Leave' },
          { id: 'Other', name: 'Other' },
          { id: 'Retired', name: 'Retired' },
          { id: 'Terminated', name: 'Terminated' },
        ]}
      />
    </Filter>
  );
};

const UserList = (props) => {
  return (
    <List
      {...props}
      perPage={10}
      pagination={<UserPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title="Carvana Collective - Users"
      actions={<Actions />}
      filters={<UserFilter />}
      filterDefaultValues={{ status: 'Active' }}
    >
      <Datagrid>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <TextField source="locationTitle" sortable={false} />
        <TextField source="status" />
        <RoleField source="role" label="Last known role" />
        <DateField source="lastLogin" label="Last activity" showTime />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
