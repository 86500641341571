import { stringify } from 'qs';
import axios from 'axios';

import { NotImplementedError } from './errors';
import initialize from './initializer';

/**
 * Maps react-admin queries to a JSONAPI REST API
 *
 * @param {string} apiUrl the base URL for the JSONAPI
 * @param {string} userSettings Settings to configure this client.
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
// eslint-disable-next-line import/no-anonymous-default-export
export default (apiUrl, opts = {}) => {
  // Initialize HTTP client library
  initialize(opts);

  return {
    getList: (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        filter: params.filter,
        page,
        perPage,
        sort: field,
        order,
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return axios({ url }).then((response) => {
        return {
          data: response.data.data,
          total: response.data.totalDocs,
        };
      });
    },

    getOne: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      return axios({ url }).then((response) => {
        return {
          data: response.data,
        };
      });
    },

    getMany: (resource, params) => {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      return axios({ url }).then((response) => {
        return {
          data: response.data.data,
          total: response.data.totalDocs,
        };
      });
    },

    getManyReference: (resource, params) => {
      throw new NotImplementedError(
        `List many reference update is not supported by this data provider`
      );
    },

    update: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      const { data } = params;

      const options = {
        method: 'PUT',
        data: JSON.stringify(data),
      };

      return axios({ url, ...options }).then((response) => {
        return {
          data: response.data,
        };
      });
    },

    updateMany: (resource, params) => {
      throw new NotImplementedError(`Batch update is not supported by this data provider`);
    },

    create: (resource, params) => {
      const url = `${apiUrl}/${resource}`;
      const options = {
        method: 'POST',
        data: JSON.stringify(params.data),
      };
      return axios({ url, ...options }).then((response) => {
        return {
          data: response.data,
        };
      });
    },

    delete: (resource, params) => {
      const url = `${apiUrl}/${resource}/${params.id}`;
      const options = { method: 'DELETE' };
      return axios({ url, ...options }).then((response) => {
        return {
          data: { id: params.id },
        };
      });
    },

    deleteMany: (resource, params) => {
      const url = `${apiUrl}/${resource}`;
      const { ids } = params;

      const options = {
        method: 'DELETE',
        data: JSON.stringify({ ids }),
      };

      return axios({ url, ...options }).then((response) => {
        return {
          data: params.ids,
        };
      });
    },
  };
};
