import React from 'react';
import { BooleanField, TopToolbar, Datagrid, EditButton, List, RefreshButton } from 'react-admin';
import KillSwitch from './KillSwitch';
import ReloadScreens from './ReloadScreens';

const Actions = (props) => {
  return (
    <TopToolbar>
      <RefreshButton />
    </TopToolbar>
  );
};

const SettingList = (props) => {
  return (
    <List
      {...props}
      perPage={10}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title="Carvana Collective - Settings / Utilities"
      bulkActionButtons={false}
      actions={<Actions />}
    >
      <Datagrid>
        <BooleanField source="active" label="Site Enabled" />
        <KillSwitch />
        <ReloadScreens />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default SettingList;
