import React from 'react';
import { Create, SimpleForm, SaveButton, TextInput, Toolbar } from 'react-admin';

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required';
  }

  if (values.title && values.title.length > 64) {
    errors.title = 'Maximum length is 64 characters';
  }

  if (!values.body) {
    errors.body = 'Message is required';
  }

  // Android Limit = 240, iOS limit = 178
  if (values.body && values.body.length > 170) {
    errors.body = 'Maximum length is 170 characters';
  }

  if (values.link && !values.link.startsWith('http://') && !values.link.startsWith('https://')) {
    errors.link = 'Link has to start with http:// or https://';
  }

  return errors;
};

const NotificationCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Send Message" redirect="show" submitOnEnter={true} />
    </Toolbar>
  );
};

const NotificationCreate = (props) => {
  return (
    <Create {...props} title="Send Message">
      <SimpleForm toolbar={<NotificationCreateToolbar />} validate={validate}>
        <TextInput label="Title" source="title" helperText="Use no more than 64 characters" />
        <TextInput
          label="Body"
          source="body"
          multiline
          helperText="Use no more than 170 characters"
        />
        <TextInput
          type="url"
          label="Link"
          source="link"
          multiline
          helperText="Use absolute link that starts with http:// or https://"
        />
      </SimpleForm>
    </Create>
  );
};

export default NotificationCreate;
