import React from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import ImageListItem from '@material-ui/core/ImageListItem';
import Typography from '@material-ui/core/Typography';
import QueueButton from './QueueButton';
import PublishButton from './PublishButton';

const cardStyle = {
  // width: '100%',
  margin: '0.5em',
  // display: 'inline-block',
  // verticalAlign: 'top',
  height: '100%',
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginLeft: '-20px',
    padding: theme.spacing(1),
  },
  actions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  missingImage: {
    color: 'red',
  },
  liveAtDiv: {
    ...theme.typography.button,
    backgroundColor: '#ddd',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },
  editButton: {
    zIndex: 3000,
  },
  assetContainer: {
    maxHeight: 250,
  },
  image: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  video: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },
  gridList: {
    listStyle: 'none',
  },
  infoSection: {
    padding: theme.spacing(1),
  },
}));

const checkUrl = function (url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

const PreviewCard = (props) => {
  const { ids, data } = props;
  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.gridContainer}>
      {ids.map((id) => {
        const record = data[id];
        const { archivedUrl } = record;
        const isImage = checkUrl(archivedUrl);
        const creator =
          record.creator !== null
            ? `${record.creator.firstName} ${record.creator.lastName}`
            : 'Email Service';

        return (
          <Grid item xs={12} sm={6} md={6} lg={4} key={id}>
            <Card style={cardStyle}>
              <ImageListItem className={classes.gridList}>
                {archivedUrl !== null && isImage && (
                  <div className={classes.assetContainer}>
                    <img src={archivedUrl} className={classes.image} alt="Post Asset" />
                  </div>
                )}

                {archivedUrl !== null && !isImage && (
                  <div className={classes.assetContainer}>
                    <video controls className={classes.video}>
                      <source type="video/mp4" src={archivedUrl} />
                    </video>
                  </div>
                )}

                <div className={classes.infoSection}>
                  {archivedUrl === null && (
                    <div>
                      <Typography variant="h5" className={classes.missingImage}>
                        No asset added
                      </Typography>
                    </div>
                  )}
                  <Typography variant="subtitle1">
                    By: {record.firstName} {record.lastName}
                  </Typography>
                  <Typography variant="body1">Creator: {creator}</Typography>
                  <Typography variant="body1">Email: {record.email}</Typography>
                  <Typography variant="body1">Subject: {record.subject}</Typography>
                  <Typography variant="body1">
                    Published: {record.published ? 'Yes' : 'No'}
                  </Typography>
                  <Typography variant="body1">
                    Created At: {moment(record.createdAt).format('MM/DD/YYYY, h:mm A')}
                  </Typography>
                  <Typography variant="body1">Likes: {record.likeCount}</Typography>
                  {record.publishedAt !== null && (
                    <Typography variant="body1">
                      Published At: {moment(record.publishedAt).format('MM/DD/YYYY, h:mm A')}
                    </Typography>
                  )}
                  <div className={classes.actions}>
                    {archivedUrl !== null && (
                      <PublishButton record={record} className={classes.editButton} />
                    )}

                    {archivedUrl !== null && (
                      <QueueButton record={record} className={classes.editButton} />
                    )}

                    {record.superPost && (
                      <div className={classes.liveAtDiv}>
                        Super post scheduled:
                        <br />
                        {moment
                          .tz(record.superPostLiveAt, record.superPostTimeZone)
                          .format('MM/DD/YYYY, h:mm A')}{' '}
                        {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                        <br />
                        thru
                        <br />
                        {moment
                          .tz(record.superPostEndAt, record.superPostTimeZone)
                          .format('MM/DD/YYYY, h:mm A')}{' '}
                        {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                      </div>
                    )}
                  </div>
                  <Typography variant="caption">
                    <strong>Location:</strong> {record.locationTitle}
                  </Typography>
                  &nbsp;
                  <Typography variant="caption">
                    <strong>Location Code:</strong> {record.locationCode}
                  </Typography>
                </div>
              </ImageListItem>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PreviewCard;
