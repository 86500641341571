import React, { Fragment } from 'react';
import { useFormState } from 'react-final-form';
import { TextInput, SelectInput } from 'react-admin';

const Timezone = (props) => {
  const { values: formvalues } = useFormState();

  if (formvalues === undefined) {
    return null;
  }

  if (formvalues.superPost === undefined || formvalues.superPost === false) {
    return <TextInput source="superPostTimeZone" disabled />;
  }

  return (
    <Fragment>
      {formvalues.superPostTimeZone === null && <TextInput source="superPostTimeZone" disabled />}

      {formvalues.superPostTimeZone !== null && (
        <SelectInput
          label="Timezone"
          source="superPostTimeZone"
          defaultValue="America/Los_Angeles"
          choices={[
            { id: 'Pacific/Honolulu', name: 'Pacific/Honolulu' },
            { id: 'America/Los_Angeles', name: 'America/Los_Angeles' },
            { id: 'America/Phoenix', name: 'America/Phoenix' },
            { id: 'America/Denver', name: 'America/Denver' },
            { id: 'America/Chicago', name: 'America/Chicago' },
            { id: 'America/New_York', name: 'America/New_York' },
          ]}
        />
      )}
    </Fragment>
  );
};

Timezone.propTypes = {};

export default Timezone;
