import axios from 'axios';
import { HttpError } from './errors';

// Handle HTTP errors.
// eslint-disable-next-line import/no-anonymous-default-export
export default (options) => {
  axios.interceptors.request.use(
    function (config) {
      // Only apply this interceptor to API requests
      if (!config.url.startsWith(`${process.env.REACT_APP_API_URL}/v1`)) {
        return config;
      }

      // Do something before request is sent
      if (!config.headers['Accept']) {
        config.headers['Accept'] = 'application/json';
      }

      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }

      if (!config.headers['Authorization']) {
        const accessToken = options.oktaAuth.getAccessToken();
        const headerValue = `Bearer ${accessToken}`;
        config.headers['Authorization'] = headerValue;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  // Response interceptor
  // https://axios-http.com/docs/interceptors
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('Error: ', error);
      const { status, data } = error.response;

      if (status < 200 || status >= 300) {
        return Promise.reject(new HttpError(data, status));
      }

      return Promise.reject(error);
    }
  );
};
