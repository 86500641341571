import React from 'react';
import {
  TopToolbar,
  CreateButton,
  Datagrid,
  EditButton,
  List,
  Pagination,
  RefreshButton,
  TextField,
} from 'react-admin';
import { RoleField } from '../shared/RoleField';

const Actions = ({ basePath }) => {
  return (
    <TopToolbar>
      <CreateButton basePath={basePath} />
      <RefreshButton />
    </TopToolbar>
  );
};

const PermissionPagination = (props) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const PermissionList = (props) => {
  return (
    <List
      {...props}
      perPage={10}
      pagination={<PermissionPagination />}
      sort={{ field: 'rank', order: 'ASC' }}
      title="Carvana Collective - Permissions"
      actions={<Actions />}
    >
      <Datagrid>
        <TextField source="rank" />
        <TextField source="group" />
        <RoleField source="role" />

        <EditButton />
      </Datagrid>
    </List>
  );
};

export default PermissionList;
